var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "list-template", class: _vm.active ? "active" : "deactive" },
    [
      _c("div", { staticClass: "side" }, [
        _c("div", { staticClass: "inside" }, [_vm._t("side")], 2),
      ]),
      _c(
        "div",
        { staticClass: "main" },
        [
          _vm._t("back-to-list"),
          _c("div", { staticClass: "main-scroll" }, [
            _c("div", { staticClass: "inside" }, [_vm._t("main")], 2),
          ]),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }